import React from 'react';
import './proj-present-desc.css';
import { connect } from 'react-redux';

const ProjPresentDesc = ({ projectInfo, txtColorNew, lessWidth1000 }) => {
  return (
    <div className='pp-desc' >
      <div>
        <div className='pp-desc-ico'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={txtColorNew} d="M11 10V16H10V11.7324C9.70583 11.9026 9.36429 12 9 12C7.89543 12 7 11.1046 7 10C7 8.89543 7.89543 8 9 8C9.74028 8 10.3866 8.4022 10.7324 9H11V10Z"/>
                <path fill={txtColorNew} opacity="0.7" d="M18 10V16H17V11.7324C16.7058 11.9026 16.3643 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C16.7403 8 17.3866 8.4022 17.7324 9H18V10Z"/>
            </svg>
        </div>
        <div className={`pp-desc-txt ${lessWidth1000 ? 'lessWidth1000' : ''}`} style={{ color: txtColorNew}} >{projectInfo.description || ''}</div>
      </div>
    </div>
  )
};


const mapStateToProps = ({ projectsPresent: {projectInfo, txtColorNew}, responsiveQuery: {lessWidth1000} }) => {
  return { projectInfo, txtColorNew, lessWidth1000 }
};
export default connect(mapStateToProps)(ProjPresentDesc);
