import { db, collection, getDocs, getStorage, ref, getDownloadURL } from './firebase';

class Services {
    async getProjects () {
        const querySnapshot = await getDocs(collection(db, "projects"));
        const projects = [];
        querySnapshot.forEach((doc) => {
            projects.push(doc.data());
        });
        return projects;
    };

    loadAndCollectImages (url, images, projName) {
        return new Promise( (resolve, reject) => {
            const storage = getStorage();
            getDownloadURL( ref(storage, url) ).then((dwUrl) => {
                const img = new Image();
                img.alt = 'Mooradesign "'+projName+'"';
                img.onload = () => {
                    images.push(img);
                    resolve();
                }
                img.setAttribute('src', dwUrl);
            }).catch( (err) => reject());
        });
    };

    getImagesRequestToken = {
       id: null,
       generate: function () {
         this.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
         return this.id;
       }
    };

    async getProjectImages (urls, projName) {
        const loadAndCollectImages = this.loadAndCollectImages;
        const getImagesRequestToken = this.getImagesRequestToken;
        const reqTokenId = await getImagesRequestToken.generate();
        const images = [];

        for (var url of urls) {
           await loadAndCollectImages(url, images, projName);
        }
        if (reqTokenId === getImagesRequestToken.id) {
            if (images.length === urls.length) {
                return(images);
            } else {
                throw new Error();
            }
        } else {
            return(false);
        }
    };
};
const services = new Services();

export default services;
