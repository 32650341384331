import React, { useEffect, useRef }  from 'react';
import './proj-present-image-item.css';
import {
    onTouchStartImage,
    onTouchMoveImage,
    onTouchEndImage,
    onMouseDownImage,
    onMouseMoveImage,
    onMouseUpImage,
    onMouseOutImage
 } from '../../../actions';

const ProjPresentImageItem = ({ el, id, txtColorNew }) => {
    const imgWrapperRef = useRef(null);
    useEffect(() => {
        const imgWrapperEl = imgWrapperRef ? imgWrapperRef.current : null;
        if (imgWrapperEl) {
            el.classList.add('pp-img');
            imgWrapperEl.innerHTML = '';
            imgWrapperEl.appendChild(el);
            el.onselectstart = () => false;
            el.ondragstart = () => false;
            el.addEventListener('touchstart', onTouchStartImage);
            el.addEventListener('touchmove', onTouchMoveImage);
            el.addEventListener('touchend', onTouchEndImage);
            el.addEventListener('mousedown', onMouseDownImage);
            el.addEventListener('mousemove', onMouseMoveImage);
            el.addEventListener('mouseup', onMouseUpImage);
            el.addEventListener('mouseout', onMouseOutImage);
        }
    });
    return (
        <div className='pp-img-container' >
            <div ref={imgWrapperRef}></div>
            <div className='pp-img-counter' style={{ color: txtColorNew }}>{ id + 1 }</div>
        </div>
    )
};

export default ProjPresentImageItem;
