import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/app';
import { Provider } from 'react-redux';
import ErrorBoundry from './components/error-boundry';
import store from './store';

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <Provider store={store} >
      <ErrorBoundry>
          <App />
      </ErrorBoundry>
  </Provider >
);
